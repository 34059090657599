import React from 'react';
import { useUser } from '../../context-providers/user/user-hooks';
import { logout } from '../../utils/fetch-api';
import Logo from '../images/Logo';
import Notifications from '../atoms/notifications/Notifications';
import { color } from '../config';
import { IconLogout } from '../icons';
import Breadcrumbs, { IBreadcrumb } from './Breadcrumbs';
import {
  ContentContainer,
  GlobalStyle,
  Header,
  LayoutContainer,
  MainRow,
  UserInfo,
} from './Layout.style';
import ModalWindow from './ModalWindow';

type NoAccessLayoutProps = {
  children: React.ReactNode;
  breadcrumbs?: IBreadcrumb[];
};

const NoAccessLayout = ({ children, breadcrumbs }: NoAccessLayoutProps) => {
  const { user } = useUser();

  return (
    <>
      <Notifications />
      <GlobalStyle />
      <LayoutContainer>
        <Header>
          <Logo fill={color.secondary.dark} responsive />
          <Breadcrumbs breadcrumbs={breadcrumbs} />
          <UserInfo>
            {!!user && (
              <span>
                {user.firstName} {user.lastName}
              </span>
            )}
            <button type="button" onClick={() => logout()}>
              Logout
              <IconLogout responsive />
            </button>
          </UserInfo>
        </Header>
        <MainRow>
          <ContentContainer>{children}</ContentContainer>
        </MainRow>
      </LayoutContainer>
      <ModalWindow />
    </>
  );
};

export default NoAccessLayout;
