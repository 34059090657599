import React from 'react';
import Head from '../../components/layout/Head';
import EulaTerms from '../../components/organisms/eula-terms/EulaTerms';
import NoAccessLayout from '../../components/layout/NoAccessLayout';

const EulaTermsPage = () => {
  return (
    <>
      <Head title="EulaTermsPage" />
      <NoAccessLayout
        breadcrumbs={[
          { link: '/eula-terms', label: 'EulaTermsPage' },
          { label: 'EulaTermsPage' },
        ]}
      >
        <EulaTerms />
      </NoAccessLayout>
    </>
  );
};

export default EulaTermsPage;
