import React, { useEffect } from 'react';
import {
  useShowModal,
  useUpdateModalStyle,
} from '../../../context-providers/ui/ui-hooks';
import ConfirmEulaTerms from '../../modals/forms/ConfirmEulaTerms';

const EulaTerms = () => {
  const showModal = useShowModal();
  const onModalStyleChange = useUpdateModalStyle();
  useEffect(() => {
    onModalStyleChange({
      content: {
        padding: '32px',
        overflow: 'auto',
      },
    });
    showModal(ConfirmEulaTerms);
  }, []);

  return <></>;
};

export default EulaTerms;
