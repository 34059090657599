import styled from 'styled-components';

/**
 * @description When you need the aesthetic of <a> hyperlink tag but you don't need the baggage that comes with it.
 */

export const NotLink = styled.span`
  color: #0055ff;
  display: initial;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    color: #0055ff91;
  }
`;
