import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import {
  useCloseModal,
  useDismissModal,
  useUpdateModalStyle,
} from '../../../context-providers/ui/ui-hooks';
import { Box } from '../../atoms/custom-elements/Box.style';
import Button from '../../atoms/button/Button';
import { GridFlexMain } from '../../atoms/grid-flex/GridFlex.style';
import Stack from '../../atoms/stack/Stack';
import { Powergraph } from '../../atoms/custom-elements/Powergraph.style';
import { NotLink } from '../../atoms/custom-elements/NotLink.style';
import EulaTermsDoc from '../../organisms/eula-terms/EulaTermsDoc';
import {
  Sticky,
  StickyGredients,
} from '../../atoms/custom-elements/Sticky.style';
import {
  getLocalStorage,
  setSessionStorage,
} from '../../../utils/local-storage';
import { getUserClaims } from '../../../utils/jwt-decode';
import { fetchAPI, logout } from '../../../utils/fetch-api';
import { ScrollBox } from '../../atoms/custom-elements/Scrollbar.style';

const ConfirmEulaTerms = () => {
  const [page, setPage] = useState(1);
  const closeModal = useCloseModal();
  const onModalStyleChange = useUpdateModalStyle();
  const onDismissModal = useDismissModal();
  const { id } = getUserClaims() || {};

  useEffect(() => {
    onModalStyleChange({
      content: {
        padding: '32px',
        overflow: 'auto',
      },
    });
    onDismissModal();
    return () => {
      onDismissModal();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onConfirmEulaTerms = async (): Promise<void> => {
    const res = await fetchAPI<{ id: number }>({
      path: `users/updateeula/${id}`,
      method: 'POST',
      body: {
        id,
      },
      responseType: 'text',
    });

    if (res.success) {
      closeModal();
      setSessionStorage('confirmEulaTerms', 'true');
      navigate('/');
    } else {
      logout();
    }
  };
  const onDoNotAgree = (): void => {
    logout();
  };

  const onKeyDownSelect = (e: any) => {
    if (e.key === 'Enter') {
      setPage(2);
    }
  };

  return (
    <>
      {page === 1 ? (
        <Stack stackMultiplier={2}>
          <Box padding="48px 42px 56px 42px">
            <Box textAlign="center">
              <h2>Terms and Conditions</h2>
              <Powergraph
                fontWeight="600"
                lineHeight="24px"
                margin="20px 0 20px 0"
              >
                This Software is made available by Onefile Ltd the "Licensor".
              </Powergraph>
              <Powergraph fontWeight="600" lineHeight="24px">
                Please read these{' '}
                <NotLink
                  tabIndex={0}
                  onKeyDown={(e) => {
                    onKeyDownSelect(e);
                  }}
                  onClick={() => setPage(2)}
                  data-qa="eula-link-conditions"
                >
                  Conditions
                </NotLink>{' '}
                carefully before accessing or using this Software. If you the
                "User" agree to be bound by them, please click on the "I Agree"
                button below. If you do not agree to be bound by the Conditions,
                then please click on the "I Do Not Agree" button below. If you
                do so, you will not be able to access or use the Software.
              </Powergraph>
            </Box>
          </Box>
          <Box borderTop padding="24px 0 24px 0">
            <GridFlexMain justify="center" align="center">
              <Box margin="0 24px 0 0">
                <Button
                  onClick={() => {
                    onDoNotAgree();
                  }}
                  variant="secondary"
                  dataQa="eula-btn-decline"
                >
                  I Do Not Agree
                </Button>
              </Box>
              <Button
                onClick={() => {
                  onConfirmEulaTerms();
                }}
                variant="primary"
                dataQa="eula-btn-accept"
              >
                I Agree
              </Button>
            </GridFlexMain>
          </Box>
        </Stack>
      ) : (
        ''
      )}
      {page === 2 ? (
        <>
          <ScrollBox>
            <Box tabIndex={0}>
              <Box padding="40px">
                <Box textAlign="center">
                  <h2>Terms and Conditions</h2>
                </Box>
                <Box margin="40px 0 20px 0">
                  <EulaTermsDoc />
                </Box>
              </Box>
            </Box>
          </ScrollBox>
          <StickyGredients />
          <Sticky>
            <Box borderTop>
              <Box padding="24px 0 24px 0">
                <GridFlexMain justify="center" align="center">
                  <Box margin="0 24px 0 0">
                    <Button
                      onClick={() => {
                        onDoNotAgree();
                      }}
                      variant="secondary"
                      dataQa="eula-btn-decline"
                    >
                      I Do Not Agree
                    </Button>
                  </Box>
                  <Button
                    onClick={() => {
                      onConfirmEulaTerms();
                    }}
                    variant="primary"
                    dataQa="eula-btn-accept"
                  >
                    I Agree
                  </Button>
                </GridFlexMain>
              </Box>
            </Box>
          </Sticky>
        </>
      ) : (
        ''
      )}
    </>
  );
};

export default ConfirmEulaTerms;
